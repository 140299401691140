
import { Vue, Component } from 'vue-property-decorator'
import { ValveDeviceInfo } from '@/types/irrigation'
import DeviceDialog from './IrrigationDeviceDialog.vue'
import ValveOpenDialog from './ValveOpenDialog.vue'

@Component({
  components: {
    DeviceDialog,
    ValveOpenDialog
  }
})
export default class IrrigationSetting extends Vue {
  deviceTableListConfig = [
    {
      label: '设备名称',
      prop: 'deviceName',
      showOverflowTooltip: true
    },
    {
      label: '设备串号',
      prop: 'deviceNumber',
      minWidth: '120',
      showOverflowTooltip: true
    },
    {
      label: '经度',
      prop: 'longitude',
      minWidth: '120',
      showOverflowTooltip: true
    },
    {
      label: '纬度',
      prop: 'latitude',
      minWidth: '120',
      showOverflowTooltip: true
    }
  ]

  setDeviceTableListConfig =[
    {
      label: '设备名称',
      prop: 'deviceName',
      showOverflowTooltip: true
    },
    {
      label: '设备串号',
      prop: 'deviceNumber',
      minWidth: '120',
      showOverflowTooltip: true
    },
    {
      label: '经度',
      prop: 'longitude',
      showOverflowTooltip: true,
      minWidth: '120'
    },
    {
      label: '纬度',
      prop: 'latitude',
      showOverflowTooltip: true,
      minWidth: '120'
    },
    {
      label: '关联区域名称',
      prop: 'projectAreaName',
      minWidth: '120',
      showOverflowTooltip: true
    }]

  private projectId: string | (string | null)[] = ''

  private areaList = []

  private currentAreaId = ''

  // 未配置电磁阀表
  private unSetVAInfo = {
    loading: false,
    tr: this.deviceTableListConfig,
    data: []
  }

  private unSetVASize= 5
  private unSetVAPage=1
  private unSetVATotal= 0

  // 未配置水表
  private unSetVMInfo = {
    loading: false,
    tr: this.deviceTableListConfig,
    data: []
  }

  private unSetVMSize = 5
  private unSetVMPage = 1
  private unSetVMTotal = 0

  // 未配置电表
  private unSetEMInfo = {
    loading: false,
    tr: this.deviceTableListConfig,
    data: []
  }

  private unSetEMSize = 5
  private unSetEMPage = 1
  private unSetEMTotal = 0

  // 未配置土壤传感器表
  private unSetSInfo = {
    loading: false,
    tr: this.deviceTableListConfig,
    data: []
  }

  private unSetSSize = 5
  private unSetSPage = 1
  private unSetSTotal = 0

  // 已配置电磁阀表
  private setVAInfo = {
    loading: false,
    tr: this.setDeviceTableListConfig,
    data: []
  }

  private setVASize = 5
  private setVAPage = 1
  private setVATotal = 0

  // 已配置水表
  private setVMInfo = {
    loading: false,
    tr: this.setDeviceTableListConfig,
    data: []
  }

  private setVMSize = 5
  private setVMPage = 1
  private setVMTotal = 0
  // 已配置电表
  private setEMInfo = {
    loading: false,
    tr: this.setDeviceTableListConfig,
    data: []
  }

  private setEMSize = 5
  private setEMPage = 1
  private setEMTotal = 0
  // 已配置土壤传感器表
  private setSInfo = {
    loading: false,
    tr: this.setDeviceTableListConfig,
    data: []
  }

  private setSSize = 5
  private setSPage = 1
  private setSTotal = 0

  // 是否显示地图弹框
  private isShowDeviceDialog = false
  // 是否显示电磁阀选择开启时间弹框
  private isShowDeviceOpen = false
  // 电磁阀开启关闭状态加载
  private valveStatusUpdateLoading: any = null
  // 存储当前选择设备信息
  private deviceInfo: ValveDeviceInfo | {} = {}

  created () {
    this.projectId = this.$route.query.projectId
    this.areaGet()
    this.getAllDevice()
  }

  // 初始化右侧设备展示
  initDevice () {
    this.getUnSetDeviceVAList()
    this.getUnSetDeviceVMList()
    this.getUnSetDeviceEMList()
    this.getUnSetDeviceSList()
    this.getSetDeviceVAList()
    this.getSetDeviceVMList()
    this.getSetDeviceEMList()
    this.getSetDeviceSList()
  }

  // 查询所有设备列表
  getAllDevice () {
    this.$axios.get(this.$apis.irrigation.selectAllDeviceList, {
      projectId: this.$route.query.projectId
    }).then((res) => {
      this.unSetVAInfo.data = res.valveNotBindDevice.list
      this.unSetVATotal = res.valveNotBindDevice.total

      this.unSetVMInfo.data = res.waterNotBindDevice.list
      this.unSetVMTotal = res.waterNotBindDevice.total

      this.unSetEMInfo.data = res.breakerNotBindDevice.list
      this.unSetEMTotal = res.breakerNotBindDevice.total

      this.unSetSInfo.data = res.soilNotBindDevice.list
      this.unSetSTotal = res.soilNotBindDevice.total

      this.setVAInfo.data = res.valveDevice.list
      this.setVATotal = res.valveDevice.total

      this.setVMInfo.data = res.waterDevice.list
      this.setVMTotal = res.waterDevice.total

      this.setEMInfo.data = res.breakerDevice.list
      this.setEMTotal = res.breakerDevice.total

      this.setSInfo.data = res.soilDevice.list
      this.setSTotal = res.soilDevice.total
    })
  }

  // 显示当前区域配置信息
  showAreaConfig (projectAreaId: string | '') {
    if (this.currentAreaId !== projectAreaId) {
      this.currentAreaId = projectAreaId || ''
      if (!this.currentAreaId) {
        this.unSetVAPage = 1
        this.unSetVMPage = 1
        this.unSetEMPage = 1
        this.unSetSPage = 1
        this.getUnSetDeviceVAList()
        this.getUnSetDeviceVMList()
        this.getUnSetDeviceEMList()
        this.getUnSetDeviceSList()
      }
      this.setVAPage = 1
      this.setVMPage = 1
      this.setEMPage = 1
      this.setSPage = 1
      this.getSetDeviceVAList()
      this.getSetDeviceVMList()
      this.getSetDeviceEMList()
      this.getSetDeviceSList()
    }
  }

  // 获取区域列表
  areaGet () {
    this.$axios.get(this.$apis.project.selectProjectAreaByList, {
      projectId: this.projectId,
      isIdimArea: '1',
      notAllArea: '1'
    }).then((res) => {
      this.areaList = res.list || []
    })
  }

  // 查看地图
  showDeviceLocation (val: object) {
    this.isShowDeviceDialog = true
    this.deviceInfo = val
  }

  // 查询当前电磁阀开启状态
  getValveStatus () {
    let num = 1
    const interval = setInterval(() => {
      const info = this.deviceInfo as ValveDeviceInfo
      this.$axios.get(this.$apis.irrigation.selectOperateStart, {
        deviceId: info.deviceId,
        num: num
      }).then((res) => {
        console.log(res)
        if (info.valveDeviceStatus !== res) {
          this.valveStatusUpdateTip(res, true)
          clearInterval(interval)
        } else {
          if (num === 5) {
            this.valveStatusUpdateTip(res, false)
            clearInterval(interval)
          } else {
            num++
          }
        }
      }).catch(() => {
        if (this.valveStatusUpdateLoading) {
          this.valveStatusUpdateLoading.close()
        }
        clearInterval(interval)
      })
    }, 5000)
  }

  // 电磁阀状态获取
  valveStatusUpdate () {
    this.valveStatusUpdateLoading = this.$loading({
      lock: true,
      text: (this.deviceInfo as ValveDeviceInfo).valveDeviceStatus === '0' ? '电磁阀正在开启' : '电磁阀正在关闭',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    })
    this.getValveStatus()
  }

  // 电磁阀状态获取,curentStatus:1 开启 0关闭
  valveStatusUpdateTip (curentStatus: string, actionStatus: boolean) {
    if (this.valveStatusUpdateLoading) {
      this.valveStatusUpdateLoading.close()
    }
    if (actionStatus) {
      this.$message.success(curentStatus === '0' ? '关闭成功' : '开启成功')
      this.getSetDeviceVAList()
    } else {
      this.$message.error(curentStatus === '1' ? '关闭失败' : '开启失败')
    }
  }

  // 开启电磁阀
  openDevice (row: ValveDeviceInfo) {
    this.isShowDeviceOpen = true
    this.deviceInfo = row
  }

  // 关闭电磁阀
  closeDevice (row: ValveDeviceInfo) {
    this.deviceInfo = row
    this.$axios.post(this.$apis.irrigation.operateShut, {
      deviceId: row.deviceId
    }).then(() => {
      this.valveStatusUpdate()
    })
  }

  // 新增区域
  addArea () {
    this.$router.push({
      name: 'irrigationAddArea',
      query: {
        projectId: this.projectId
      }
    })
  }

  // 编辑区域
  editArea (id: string) {
    this.$router.push({
      name: 'irrigationUpdateArea',
      query: {
        projectId: this.projectId
      },
      params: { id }
    })
  }

  // 删除区域
  deleteArea (id: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        this.$axios.post(this.$apis.irrigation.irrigationAreaDelete, {
          projectAreaId: id
        })
          .then(() => {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.areaGet()
            this.initDevice()
          })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  // 获取未配置设备电磁阀列表
  getUnSetDeviceVAList () {
    this.unSetVAInfo.loading = true
    this.$axios.get(this.$apis.irrigation.irrigationAreaDeviceNot, {
      deviceTypeId: '1007',
      projectId: this.projectId,
      page: this.unSetVAPage,
      size: this.unSetVASize
    }).then((res) => {
      this.unSetVAInfo.data = res.list
      this.unSetVATotal = res.total
      this.unSetVAInfo.loading = false
    }).catch(() => {
      this.unSetVAInfo.loading = false
    })
  }

  // 获取未配置设备水表列表
  getUnSetDeviceVMList () {
    this.unSetVMInfo.loading = true
    this.$axios.get(this.$apis.irrigation.irrigationAreaDeviceNot, {
      deviceTypeId: '1004',
      projectId: this.projectId,
      page: this.unSetVMPage,
      size: this.unSetVMSize
    }).then((res) => {
      this.unSetVMInfo.data = res.list
      this.unSetVMTotal = res.total
      this.unSetVMInfo.loading = false
    }).catch(() => {
      this.unSetVMInfo.loading = false
    })
  }

  // 获取未配置设备电表列表
  getUnSetDeviceEMList () {
    this.unSetEMInfo.loading = true
    this.$axios.get(this.$apis.irrigation.irrigationAreaDeviceNot, {
      deviceTypeId: '1014',
      projectId: this.projectId,
      page: this.unSetEMPage,
      size: this.unSetEMSize
    }).then((res) => {
      this.unSetEMInfo.data = res.list
      this.unSetEMTotal = res.total
      this.unSetEMInfo.loading = false
    }).catch(() => {
      this.unSetEMInfo.loading = false
    })
  }

  // 获取未配置设备土壤传感器列表
  getUnSetDeviceSList () {
    this.unSetSInfo.loading = true
    this.$axios.get(this.$apis.irrigation.irrigationAreaDeviceNot, {
      deviceTypeId: '1001',
      projectId: this.projectId,
      page: this.unSetSPage,
      size: this.unSetSSize
    }).then((res) => {
      this.unSetSInfo.data = res.list
      this.unSetSTotal = res.total
      this.unSetSInfo.loading = false
    }).catch(() => {
      this.unSetSInfo.loading = false
    })
  }

  // 获取已配置设备电磁阀列表
  getSetDeviceVAList () {
    this.setVAInfo.loading = true
    this.$axios.get(this.$apis.irrigation.irrigationAreaDevice, {
      projectAreaId: this.currentAreaId,
      deviceTypeId: '1007',
      projectId: this.projectId,
      page: this.setVAPage,
      size: this.setVASize
    }).then((res) => {
      this.setVAInfo.data = res.list
      this.setVATotal = res.total
      this.setVAInfo.loading = false
    }).catch(() => {
      this.setVAInfo.loading = false
    })
  }

  // 获取已配置设备水表列表
  getSetDeviceVMList () {
    this.setVMInfo.loading = true
    this.$axios.get(this.$apis.irrigation.irrigationAreaDevice, {
      projectAreaId: this.currentAreaId,
      deviceTypeId: '1004',
      projectId: this.projectId,
      page: this.setVMPage,
      size: this.setVMSize
    }).then((res) => {
      this.setVMInfo.data = res.list
      this.setVMTotal = res.total
      this.setVMInfo.loading = false
    }).catch(() => {
      this.setVMInfo.loading = false
    })
  }

  // 获取已配置设备电表列表
  getSetDeviceEMList () {
    this.setEMInfo.loading = true
    this.$axios.get(this.$apis.irrigation.irrigationAreaDevice, {
      projectAreaId: this.currentAreaId,
      deviceTypeId: '1014',
      projectId: this.projectId,
      page: this.setEMPage,
      size: this.setEMSize
    }).then((res) => {
      this.setEMInfo.data = res.list
      this.setEMTotal = res.total
      this.setEMInfo.loading = false
    }).catch(() => {
      this.setEMInfo.loading = false
    })
  }

  // 获取已配置设备土壤传感器列表
  getSetDeviceSList () {
    this.$axios.get(this.$apis.irrigation.irrigationAreaDevice, {
      projectAreaId: this.currentAreaId,
      deviceTypeId: '1001',
      projectId: this.projectId,
      page: this.setSPage,
      size: this.setSSize
    }).then((res) => {
      this.setSInfo.data = res.list
      this.setSTotal = res.total
      this.setSInfo.loading = false
    })
  }
}
