
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import AMapLoader from '@amap/amap-jsapi-loader'
import { mapKey } from '@/utils/config'
import icon from '@/assets/img/icon_location.png'
import { drawPolygon } from '@/utils/formatData'

type Event = {
  lnglat: {
    lng: number;
    lat: number;
  };
}

type Info = {
  deviceNumber: string;
  longitude: number | null;
  latitude: number | null;
}
@Component
export default class IrrigationDeviceDialog extends Vue {
  @Prop() private isShow!: boolean;
  @Prop() private deviceLocationInfo!: object;

  private info: Info = {
    deviceNumber: '',
    longitude: null,
    latitude: null
  }

  private isShowDialog = this.isShow

  @Watch('isShow')
  getIsShow (val: boolean) {
    this.isShowDialog = val
    if (val) {
      this.loadData()
    }
  }

  @Watch('deviceLocationInfo')
  getDeviceLocationInfo (val: object) {
    this.info = JSON.parse(JSON.stringify(val))
  }

  loadData () {
    this.$axios.get(this.$apis.project.selectProjectAreaById, {
      projectId: this.$route.query.projectId
    }).then((res) => {
      this.loadMap(res.projectInfo, res.projectLocation)
    })
  }

  loadMap (projectInfo: {longitude: number; latitude: number}, list: Array<{ longitude: number; latitude: number; fenceCode: string }>) {
    AMapLoader.load({
      key: mapKey,
      version: '2.0',
      plugins: []
    }).then(() => {
      const center: [number, number] = [Number(projectInfo.longitude), Number(projectInfo.latitude)]
      const map = new AMap.Map('container', {
        center: center,
        zoom: 11
      })
      // 画电子围栏
      drawPolygon(map, list, null, null)
      if (this.info.longitude && this.info.latitude) {
        // 打点
        const marker = new AMap.Marker({
          icon: icon,
          offset: new AMap.Pixel(-11, -11),
          position: new AMap.LngLat(this.info.longitude, this.info.latitude)
        })
        map.add(marker)
      }
    })
  }
}
