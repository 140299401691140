
import { Component, Vue, Prop } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'

@Component
export default class ValveOpenDialog extends Vue {
  @Prop() private isShow!: boolean;
  @Prop() private deviceId!: string;

  private loading = false
  private info = {
    duration: '10'
  }

  private options = [{
    value: '10',
    label: '10分钟'
  }, {
    value: '15',
    label: '15分钟'
  }, {
    value: '20',
    label: '20分钟'
  }, {
    value: '40',
    label: '40分钟'
  }]

  private isSubmit = false
  private rules = {
    duration: [
      { required: true, message: '请选择或输入合理开启时间', trigger: 'change' }
    ]
  }

  onsubmit () {
    (this.$refs.addUserInfo as ElForm).validate(valid => {
      if (valid) {
        this.isSubmit = true
        this.loading = true
        this.info.duration = parseFloat(this.info.duration).toString()
        console.log(this.info.duration)
        this.$axios.post(this.$apis.irrigation.operateStart, {
          deviceId: this.deviceId,
          duration: this.info.duration
        }).then(() => {
          this.$emit('update:isShow', false)
          this.$emit('valveStatusUpdate')
        }).finally(() => {
          this.isSubmit = false
        })
      }
    })
  }

  selectChange () {
    if (!/^[1-9][0-9]*$/.test(this.info.duration.toString()) || Number(this.info.duration) < 0 || Number(this.info.duration) > 60) {
      this.info.duration = ''
    } else {
      this.info.duration = this.info.duration + '分钟'
    }
  }

  closeDialog () {
    this.info.duration = '10'
    this.$emit('update:isShow', false)
  }
}
